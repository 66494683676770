import {createStore} from "vuex";
import Sup from "@/support/support";
// import axios from "axios";
import gPost from "@/support/gPost";
import support from "@/support/support";


const store = createStore(
    {
        strict: false,

        state: {
            dataInitialized: false,
            apiToken: null,
            shopId: null,
            shopLoc: null,
            showJoke: false,
            showShop: false,
            refreshInterval: 15 * 60 * 1000,
            user: null,
            userRights: null,
            curUserRights: null,
            baseApiUrl: null,
            curInterval: null,
            baseUrl: window.location.origin,
            userToken: null,
            userId: null,
            login: true,
            myTopId: null,
            newsRead: true,
            topNewsRead: true,
            lastNewsRead: "2021-11-01",
            lastTopNewsRead: "2021-11-01",
            storeRefURL: "",
            lastOrderNo: "",
            consultant: "",
            consultantData: null,
            dataLoading: false,
            actuality: "",
            gallery: [],
            myData: [],
            userList: [],
            shadowList: [],
            clientList: [],
            ordersList: [],
            invoicesList: [],
            giftList: [],
            benefitList: [],
            listMsg: [],
            newsList: [],
            ticketList: [],
            topNewsList: [],
            category: [],
            selectedCategory: "Katalog",
            products: [],
            jokes: [],
            man: ["lady.png", "lady2.png", "lady2_2.png", "lady3.png", "lady4.png"],
            deliveryData: [],
            paymentData: [],
            basket: [],
            basketId: null,
            orderNo: null,
            orderCode: null,
            id: null,
            publicId: null,

            delivery: "",
            payment: "",
            note: "",
            souhlas: false,
            gdpr: false,
            articles: [],
            media: [],

            total: 0,
            variable: "",
            grandTotal: 0,


            freeDelivery: false,
            freeDeliveryLimit: 2000000,

            packetaApiKey: "",
            packetaName: "",
            packetaStreet: "",
            packetaZip: "",
            packetaCity: "",
            packetaId: null,


            voucherValue: 0,
            voucherId: "",
            hasVoucher: false,
            voucherCovered: false,
            voucherType: "0",

            name: "",
            surname: "",
            email: "",
            mobil: "",
            street: "",
            city: "",
            zip: "",
            country: "Česká Republika",
            nameError: "",
            surnameError: "",
            emailError: "",
            mobilError: "",
            streetError: "",
            cityError: "",
            zipError: "",
            countryError: "",

            cName: "",
            cSurname: "",
            ico: "",
            dic: "",
            companyName: "",
            cStreet: "",
            cCity: "",
            cZip: "",
            cCountry: "Česká Republika",
            cNameError: "",
            cSurnameError: "",
            icoError: "",
            companyNameError: "",
            cStreetError: "",
            cCityError: "",
            cZipError: "",
            cCountryError: "",

            companyInvoice: false,

            isInternal: false

        },

        mutations: {
            setTotal(cur, newValue){
                cur.total = newValue
            },

            setGrandTotal(cur, newValue){
                cur.grandTotal = newValue
            },

            setVariable(cur, newValue){
              cur.variable = newValue
            },

            setId(cur, newValue) {
                cur.id = newValue;
            },

            setActuality(cur, newValue) {
                cur.actuality = newValue;
            },

            setShowShop(cur, newValue) {
                cur.showShop = newValue;
            },

            setShowJoke(cur, newValue) {
                cur.showJoke = newValue;
            },

            setPublicId(cur, newValue) {
                cur.publicId = newValue;
            },

            setOrderCode(cur, newValue) {
                cur.orderCode = newValue;
            },

            setVoucherCovered(con, newValue) {
                con.voucherCovered = newValue;
            },

            setSouhlas(cur, newValue) {
                cur.souhlas = newValue;
            },

            setGdpr(cur, newValue) {
                cur.gdpr = newValue;
            },

            setPacketaId(cur, newValue) {
                cur.packetaId = newValue;
            },

            setPacketaApiKey(cur, newValue) {
                cur.packetaApiKey = newValue;
            },

            setPacketaName(cur, newValue) {
                cur.packetaName = newValue;
            },

            setPacketaStreet(cur, newValue) {
                cur.packetaStreet = newValue;
            },

            setPacketaZip(cur, newValue) {
                cur.packetaZip = newValue;
            },

            setPacketaCity(cur, newValue) {
                cur.packetaCity = newValue;
            },


            setArticles(con, newValue) {
                con.articles = newValue;
            },

            setMedia(con, newValue) {
                con.media = newValue;
            },

            setDataLoading(con, newValue) {
                con.dataLoading = newValue;
            },

            setDataInitialized(con, newValue) {
                con.dataInitialized = newValue;
                // console.log(this.$cookies.keys());
                // console.log("Vypisu tady neco " + this.aNecoJineho);
                // console.log(this.$cookies.get('user').name);
                // console.log(this.$cookies.get('cart'));
            },

            setBaseApiUrl(con, newValue) {
                con.baseApiUrl = newValue;
            },

            setMyTopId(con, newValue) {
                con.myTopId = newValue;
            },

            setApiToken(con, newValue) {
                con.apiToken = newValue;
            },

            setTicketList(con, newValue) {
                con.ticketList = newValue;
            },

            setShopId(con, newValue) {
                con.shopId = newValue;
            },

            setShopLoc(con, newValue) {
                con.shopLoc = newValue;
            },

            setCurInterval(con, newValue) {
                con.curInterval = newValue;
            },

            setGallery(cur, newValue) {
                cur.gallery = newValue;
            },

            setGiftList(con, newValue) {
                con.giftList = newValue;
            },

            setBenefitList(con, newValue) {
                con.benefitList = newValue;
            },

            setNote(con, newValue) {
                con.note = newValue;
            },

            setRefreshInterval(con, newValue) {
                con.refreshInterval = newValue * 60 * 1000;
            },

            setLastNewsRead(con, newValue) {
                con.lastNewsRead = newValue;
            },

            setNewsRead(con, newValue) {
                con.newsRead = newValue;
            },

            setLastTopNewsRead(con, newValue) {
                con.lastTopNewsRead = newValue;
            },

            setTopNewsRead(con, newValue) {
                con.topNewsRead = newValue;
            },

            setConsultant(con, newValue) {
                con.consultant = newValue;
            },

            setConsultantData(con, newValue) {
                con.consultantData = newValue;
            },

            setLastOrderNo(cur, newValue) {
                cur.lastOrderNo = newValue;
            },

            setLogin(cur, newValue) {
                cur.login = newValue;
            },

            setMyData(cur, newValue) {
                cur.myData = newValue
            },

            setBaseUrl(cur, newValue) {
                cur.baseUrl = newValue;
            },

            setStoreUrl(cur, newValue) {
                cur.storeRefURL = newValue;
            },

            setUserToken(cur, newValue) {
                cur.userToken = newValue;
            },

            setUserId(cur, newValue) {
                cur.userId = newValue;
            },

            setUserList(cur, newValue) {
                cur.userList = newValue;
            },

            setShadowList(cur, newValue) {
                cur.shadowList = newValue;
            },

            setOrdersList(cur, newValue) {
                cur.ordersList = newValue;
            },

            setNewsList(cur, newValue) {
                cur.newsList = newValue.sort((a, b) => Sup.getDate(b.date).getTime() - Sup.getDate(a.date).getTime());
            },

            setTopNewsList(cur, newValue) {
                cur.topNewsList = newValue.sort((a, b) => Sup.getDate(b.date).getTime() - Sup.getDate(a.date).getTime());
            },

            setInvoicesList(cur, newValue) {
                cur.invoicesList = newValue;
            },

            setClientList(cur, newValue) {
                cur.clientList = newValue;
            },

            setNewMsg(cur, newValue) {
                console.log("Zprava: " + newValue);
                cur.listMsg.push({msg: newValue.msg, msgType: newValue.msgType});
            },

            removeMsg(cur) {
                cur.listMsg.shift();
            },

            clearMsg(cur) {
                cur.listMsg = [];
            },

            setBasket(cur, newValue) {
                cur.basket = newValue;
            },

            setBasketId(cur, newValue) {
                cur.basketId = newValue;
            },

            setOrderNo(cur, newValue) {
                cur.orderNo = newValue;
            },


            setJokeList(cur, newValue) {
                cur.jokes = newValue;
            },

            setDeliveryList(cur, newValue) {
                cur.deliveryData = newValue;
            },

            setPaymentList(cur, newValue) {
                cur.paymentData = newValue;
            },


            setProdList(cur, newValue) {
                cur.products = newValue;
            },

            setCategoryList(cur, newValue) {
                cur.category = newValue;
            },

            setSelectedCategory(cur, newValue) {
                cur.selectedCategory = newValue;
            },

            setUser(cur, newValue) {
                cur.user = newValue;
            },

            setUserRights(cur, newValue) {
                cur.userRights = newValue;
            },

            setCurUserRights(cur, newValue) {
                cur.curUserRights = newValue;
            },

            setDelivery(cur, newValue) {
                cur.delivery = newValue;
            },

            setPayment(cur, newValue) {
                cur.payment = newValue;
            },

            setFreeDelivery(con, newValue) {
                con.freeDelivery = newValue;
            },

            setFreeDeliveryLimit(con, newValue) {
                con.freeDeliveryLimit = newValue;
            },

            setName(cur, newValue) {
                cur.name = newValue;
            },

            setSurName(cur, newValue) {
                cur.surname = newValue;
            },

            setEmail(cur, newValue) {
                cur.email = newValue;
            },

            setMobil(cur, newValue) {
                cur.mobil = newValue;
            },

            setStreet(cur, newValue) {
                cur.street = newValue;
            },

            setCity(cur, newValue) {
                cur.city = newValue;
            },

            setZip(cur, newValue) {
                cur.zip = newValue;
            },

            setCountry(cur, newValue) {
                cur.country = newValue;
            },

            setCName(cur, newValue) {
                cur.cName = newValue;
            },

            setCompanyInvoice(cur, newValue) {
                cur.companyInvoice = newValue;
            },

            setCSurName(cur, newValue) {
                cur.cSurname = newValue;
            },

            setIco(cur, newValue) {
                cur.ico = newValue;
            },

            setDic(cur, newValue) {
                cur.dic = newValue;
            },

            setCompanyName(cur, newValue) {
                cur.companyName = newValue;
            },

            setCStreet(cur, newValue) {
                cur.cStreet = newValue;
            },

            setCCity(cur, newValue) {
                cur.cCity = newValue;
            },

            setCZip(cur, newValue) {
                cur.cZip = newValue;
            },

            setCCountry(cur, newValue) {
                cur.cCountry = newValue;
            },

            setNameError(cur, newValue) {
                cur.nameError = newValue;
            },

            setSurNameError(cur, newValue) {
                cur.surnameError = newValue;
            },

            setEmailError(cur, newValue) {
                cur.emailError = newValue;
            },

            setMobilError(cur, newValue) {
                cur.mobilError = newValue;
            },

            setStreetError(cur, newValue) {
                cur.streetError = newValue;
            },

            setCityError(cur, newValue) {
                cur.cityError = newValue;
            },

            setZipError(cur, newValue) {
                cur.zipError = newValue;
            },

            setCountryError(cur, newValue) {
                cur.countryError = newValue;
            },

            setCNameError(cur, newValue) {
                cur.cNameError = newValue;
            },

            setCSurNameError(cur, newValue) {
                cur.cSurnameError = newValue;
            },

            setIcoError(cur, newValue) {
                cur.icoError = newValue;
            },

            setCompanyNameError(cur, newValue) {
                cur.companyNameError = newValue;
            },

            setCStreetError(cur, newValue) {
                cur.cStreetError = newValue;
            },

            setCCityError(cur, newValue) {
                cur.cCityError = newValue;
            },

            setCZipError(cur, newValue) {
                cur.cZipError = newValue;
            },

            setCCountryError(cur, newValue) {
                cur.cCountryError = newValue;
            },

            setHasVoucher(con, newValue) {
                con.hasVoucher = newValue;
            },

            setVoucherValue(con, newValue) {
                con.voucherValue = newValue;
            },

            setVoucherType(con, newValue) {
                con.voucherType = newValue;
            },

            setVoucherId(con, newValue) {
                con.voucherId = newValue;
            },

        },


        actions: {

            async initData(con) {
                con.commit('setDataLoading', true);
                let param = {
                    task: "getAllData",
                    // shopId: con.getters.getBaseShop
                };
                console.log(param);
                await gPost(con.getters.getUrlAPI, param,
                    ({data}) => {
                        con.commit('setCategoryList', data.catList);
                        con.commit('setProdList', data.prodList);
                        con.commit('setJokeList', data.jokeList);
                        con.commit('setDeliveryList', data.deliveryList);
                        con.commit('setPaymentList', data.paymentList);
                        con.commit('setGiftList', data.giftList);
                        // con.commit('setDataLoaded', true);
                    },
                    () => console.log("CHYBA")
                );

                param = {
                    task: "getAllArticles",
                }
                console.log(param);
                await gPost(con.getters.getUrlAPI, param,
                    ({data}) => {
                        // console.log(success)
                        con.commit('setArticles', data.articles);
                        // this.sendMsg();
                    },
                    (err) => console.log(err)
                );

                param = {
                    task: "getAllMedia",
                }
                console.log(param);
                await gPost(con.getters.getUrlAPI, param,
                    ({data}) => {
                        // console.log(success)
                        con.commit('setMedia', data.media.sort((a, b) => Sup.getShortDate(b.publishDate).valueOf() - Sup.getShortDate(a.publishDate).valueOf()));
                        // this.sendMsg();
                    },
                    (err) => console.log(err)
                );

                // let actual = con.state.articles.filter(item => item.category.find(cat => cat === "ACTUAL"));
                let actual = [...con.state.articles.filter(item => item.category.some(item => item === "ACTUAL")).filter(item => item.active === "1")];

                if (actual) {
                    let cumul = ""
                    actual.forEach(item => cumul += item.text);
                    con.commit('setActuality', cumul);

                }
                await con.dispatch('getGalleryList');
                console.log("Hotovo");
                con.commit('setDataLoading', false);

            },


            clearCart(con) {
                con.commit('setBasketId', null);
                con.commit('setBasket', []);
                con.commit('setOrderNo', null);
                localStorage.removeItem("basketId");
            },

            async insertToCart(con, data) {
                console.log("VKLADAM DO KOSIKU **********************************************************************")
                // let bask = con.state.basket || [];
                let bask = con.state.basket;
                if (!bask || bask.length == 0) {
                    console.log("Cekam na ID kosiku")
                    await con.dispatch('getBasketId')
                        .then(() => console.log("Uz mam ID kosiku"));
                }
                console.log("JSem za cekanim na ID kosiku")
                // let item = bask.find(item => (item.id === data.id) && (item.varId === data.varId) && (item.alc === data.alc) && (item.custValue === data.custValue));
                // let item = bask.find(item => (item.id === data.id) && arrayCompare(item.variant, data.variant));
                let item = bask.find(item => (item.id === data.id) && support.arrayComp(item.variant, data.variant));
                console.log(item);
                if (item) {
                    item.pcs += data.pcs;
                } else {
                    data['cartRow'] = con.state.basket.reduce((max, item) => (max < item.cartRow) ? item.cartRow : max, -1) + 1;
                    bask.push(data);
                }
                console.log("Tady zacinam ukladat data na server");
                con.commit('setBasket', bask);
                localStorage.setItem("basketData", JSON.stringify(bask));
                storeBasket(con, con.state.basketId, bask);
            },

            changeCartPcs(con, data) {
                console.log("ZMENA POCTU");
                let bask = con.state.basket;
                if (data.pcs !== 0) {
                    let item = bask.find(item => (item.cartRow === data.cartRow));
                    if (item) {
                        item.pcs = data.pcs;
                    }
                } else {
                    bask = bask.filter(item => !((item.cartRow === data.cartRow)));
                }
                con.commit('setBasket', bask);
                if (bask.length === 0) {
                    removeBasket(con, con.state.basketId);
                    con.commit('setBasketId', null);
                    localStorage.removeItem("basketId");
                    localStorage.removeItem("basketData");
                } else {
                    localStorage.setItem("basketData", JSON.stringify(bask));
                    storeBasket(con, con.state.basketId, con.state.basket);
                }
            },

            async getBasketId(con) {
                console.log("In GetBasketId");
                let param = {
                    task: "getBasketId",
                    // shopId: con.getters.getBaseShop
                }
                await gPost(con.getters.getUrlAPI, param,
                    ({data}) => {
                        con.commit('setBasketId', data.basketId);
                        localStorage.clear();
                        localStorage.setItem("basketId", data.basketId);

                    },
                    () => console.log("CHYBA")
                );
            },

            getTmpBasketData(con, basketId) {
                let param = {
                    task: "getTmpBasketData",
                    basketId
                    // shopId: con.getters.getBaseShop
                }
                gPost(con.getters.getUrlAPI, param,
                    ({data}) => {
                        con.commit('setBasket', data.basket);
                    },
                    () => console.log("CHYBA")
                );
            },

            getBasketDataFromLocal(con, basketData) {
                con.commit('setBasket', JSON.parse(basketData));
            },

            clearBasket(con) {
                con.commit('setBasket', []);
                removeBasket(con, con.state.basketId);
                con.commit('setBasketId', null);
                // con.commit('setTotal', 0)
                // con.commit('setVariable', "")
                localStorage.removeItem("basketId");
                localStorage.removeItem("basketData");
            },

            async getGalleryList(con) {
                let param = {
                    task: 'getGallery',
                    // ...data
                }

                await gPost(con.getters.getUrlAPI, param,
                    ({data}) => {
                        // console.log(data.msg);
                        con.commit('setGallery', data.galleryList);
                    },
                    () => console.log("CHYBA")
                );

            },

            async storeOrder(con, payOnline) {
                console.log("tady chci ulozit data");
                let basketId = con.state.basketId;
                let param = {
                    task: 'storeOrder',
                    // shopId: con.getters.getBaseShop,
                    refId: con.state.consultant,
                    shadeUserId: con.state.shadeUserId,
                    basketId: basketId,
                    deliveryType: con.state.delivery,
                    paymentType: con.state.payment,
                    invoiceData: {
                        name: con.state.name,
                        surname: con.state.surname,
                        email: con.state.email,
                        mobil: con.state.mobil,
                        street: con.state.street,
                        city: con.state.city,
                        zip: con.state.zip,
                        country: con.state.country,
                        companyInvoice: con.state.companyInvoice ? {
                            companyName: con.state.companyName,
                            name: con.state.cName,
                            surname: con.state.cSurname,
                            ico: con.state.ico,
                            dic: con.state.dic,
                            street: con.state.cStreet,
                            city: con.state.cCity,
                            zip: con.state.cZip,
                            country: con.state.cCountry,
                        } : null,
                    },
                    deliveryData: {
                        packeta: {
                            packetaId: con.state.packetaId,
                            packetaName: con.state.packetaName,
                            packetaStreet: con.state.packetaStreet,
                            packetaCity: con.state.packetaCity,
                            packetaZip: con.state.packetaZip
                        },
                        post: {
                            name: con.state.name,
                            surname: con.state.surname,
                            email: con.state.email,
                            mobil: con.state.mobil,
                            street: con.state.street,
                            city: con.state.city,
                            zip: con.state.zip,
                            country: con.state.country,
                        }
                    },
                    voucherData: {
                        hasVoucher: con.state.hasVoucher,
                        voucherId: con.state.voucherId,
                        voucherValue: con.state.voucherValue,
                        voucherType: con.state.voucherType,

                    },
                    cartItems: con.state.basket,
                    deliveryPrice: con.state.freeDelivery ? 0 : con.state.deliveryData.find(item => item.id === con.state.delivery).price,
                    paymentPrice: con.state.paymentData.find(item => item.id === con.state.payment).price,
                    cartTotal: con.state.basket.reduce((sum, item) => sum + item.pcs * item.price, 0),
                    currency: "CZK",
                    giftId: con.state.giftId,
                    note: {
                        orderNote: jsonEscape(con.state.note)
                    }

                }
                await gPost(con.getters.getUrlAPI, param,
                    (({data}) => {
                        con.commit('setVariable', data.variable)
                        con.commit('setTotal', data.total)
                        con.commit('setOrderNo', data.orderNo);
                        con.commit('setOrderCode', data.orderCode);
                    }),
                    (res) => console.log("CHYBA  " + res)
                ).then(async () => {
                    console.log("Spustim tady iniciaci brany")
                    con.commit('setVoucherCovered', false);
                    if (payOnline) {
                        // window.alert("STOP");
                        // if (con.state.payment === "5" || con.state.payment === "7") {
                        let par = {
                            task: "initPayment",
                            basketId: basketId,
                            // shopId: con.getters.getBaseShop
                        }
                        await gPost(con.getters.getUrlAPI, par, ({data}) => {

                                if (data.redirectURL) {
                                    window.open(data.redirectURL, "_self");
                                }

                                con.commit('setPublicId', data.publicId);
                                con.commit('setId', data.id);


                                con.commit('setVoucherCovered', data.voucherCovered);
                                console.log('**************VOUCHER Covered ' + con.state.voucherCovered);
                                if (!data.voucherCovered) {
                                    console.log("Nacteny param " + data.params);
                                    window.open(data.params, "_self");
                                    // window.open(data.params, "_blank");
                                }
                            },
                            (res) => console.log("CHYBA " + res)
                        );
                        // }
                        // if (con.state.payment === "GATE_COMGATE") {
                        //     window.open(con.state.payUrl + con.state.basketId, "_self");
                        // }
                    }
                })

            },

            sendShopEmail(con, data) {
                let param = {
                    task: "sendShopEmail",
                    // shopId: con.getters.getBaseShop,
                    basketId: data.basketId,
                    orderId: data.orderId,
                    orderCode: data.orderCode
                }
                gPost(con.getters.getUrlAPI, param,
                    ({data}) => {
                        console.log('Basket ID ' + data.basketId);
                    },
                    () => console.log("CHYBA")
                );
            },

        },

        getters: {

            isLoading(state) {
                return state.dataLoading;
            },

            getUrlAPI(state) {
                return state.baseApiUrl;
            },

            getBasketSize(state) {
                return state.basket ? state.basket.reduce((tot, item) => item.pcs + tot, 0) : 0;
                // return state.basket.length;
            },

            master(state) {
                return !!(state.curUserRights & 512);
            },

            top(state) {
                return !!(state.curUserRights & 32);
            },

            admin(state) {
                return !!(state.curUserRights & 128);
            },

            expedice(state) {
                return !!(state.curUserRights & 2);
            },

            validUser(state) {
                return !!(state.curUserRights > 0);

            },

            getBaseShop(state) {
                return state.baseShop;
            },

            getArticles(state) {
                return state.articles;
            },


            getVariantParamInput: (state) => (itemId) => {
                let result = null;
                let item = state.products.find((item) => item.itemId === itemId);
                if (item && item.param && item.param.variant) {
                    // result = item.param.base.find( data => data.section === "MAIN_PARAM");
                    result = item.param.variant.find((variant) => variant.type === 'CUSTVALUE');
                }
                return result;
            },

            getVariantParamRadio: (state) => (itemId) => {
                let result = null;
                let item = state.products.find((item) => item.itemId === itemId);
                if (item && item.param && item.param.variant) {
                    // result = item.param.base.find( data => data.section === "MAIN_PARAM");
                    result = item.param.variant.find((variant) => variant.type === 'RADIO');
                }
                return result;
            },

            getVariantParamList: (state) => (itemId) => {
                let result = null;
                let item = state.products.find((item) => item.itemId === itemId);
                if (item && item.param && item.param.variant) {
                    // result = item.param.base.find( data => data.section === "MAIN_PARAM");
                    result = item.param.variant.find((variant) => variant.type === 'LIST');
                }
                return result;
            },

            getVariantParam: (state) => (itemId) => {
                let result = null;
                let item = state.products.find((item) => item.itemId === itemId);
                if (item && item.param && item.param.variant) {
                    // result = item.param.base.find( data => data.section === "MAIN_PARAM");
                    result = item.param.variant;
                }
                return result;
            },

            getVariantParamSet: (state) => (itemId) => {
                let result = [];
                let item = state.products.find((item) => item.itemId === itemId);
                if (item && item.param && item.param.set) {
                    result = item.param.set;
                }
                if (item && item.param && item.param.variant) {
                    let variant = {
                        setId: "default",
                        variant: item.param.variant
                    };

                    result.push(variant);
                }
                return result;
            },

            getBaseParam: (state) => (itemId) => {
                let result = null;
                let item = state.products.find((item) => item.itemId === itemId);
                if (item && item.param && item.param.base) {
                    // result = item.param.base.find( data => data.section === "MAIN_PARAM");
                    result = item.param.base;
                }
                return result;
            },

            getInfoParam: (state) => (itemId) => {
                let result = null;
                let item = state.products.find((item) => item.itemId === itemId);
                if (item && item.param && item.param.info) {
                    // result = item.param.base.find( data => data.section === "MAIN_PARAM");
                    result = item.param.info;
                }
                return result;
            },


        },


    }
)

export default store;

/*
function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}
*/

/*
function arrayCompare(arr, obj) {
    console.log("=============================================================================");
    let result = arr.every(item => obj.every(o => shallowEqual(item, o)));
    return result;
}
*/

/*
function arrayComp(arr, obj) {
    console.log("New Array Compare");
    let a = JSON.stringify(arr);
    let b = JSON.stringify(obj);
    let result = a === b;
    console.log("Vysledek porovnani " + result);
    return result;
}
*/


function jsonEscape(str) {
    return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
    // return str.replace(/\n/g, "<br>").replace(/\r/g, "<br>").replace(/\t/g, " ");
}

function storeBasket(con, basketId, basket) {
    console.log(basket);
    let param = {
        task: "storeBasketData",
        basketId: basketId,
        basket: basket
    }

    gPost(con.getters.getUrlAPI, param,
        ({data}) => {
            console.log(data);
        },
        (err) => console.log("CHYBA" + err)
    );
}

function removeBasket(con, basketId) {
    let param = {
        task: "removeBasketData",
        basketId: basketId,
    }

    gPost(con.getters.getUrlAPI, param,
        ({data}) => {
            console.log(data);
        },
        (err) => console.log("CHYBA" + err)
    );

}